.article-code {
  background: bisque;
  padding: 2px;
  margin-left: 6px;
  margin-right: 6px;
}

.article-anchor {
  margin-left: 4px;
  margin-right: 4px;
}

.article-bold {
  margin-left: 4px;
  margin-right: 4px;
}
