.card {
  border: transparent;
  background-color: transparent;
  align-items: center;
  text-align: center;
}

#learning-cards {
  padding: 40px;
}

.learning-card {
  height: 18rem;
  width: 18rem;
}

.card-img-top {
  align-self: center;
  width: 50%;
  height: 50%;
}
